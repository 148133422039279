import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {environment} from '../environments/environment';
import {CustomAuthModule} from '@innobile/authmodule';
import {SharedModule} from './shared/shared.module';
import {CompanyUser} from './models/CompanyUser';
import {CompaniesInterceptor} from './shared/interceptors/companies.interceptor';
import {SharedVariablesService} from './services/shared-variables.service';
import {TableWebSocketAdapter} from '@innobile/tablemodule';
import {TableSocketAdapterService} from './services/table-socket-adapter.service';
import {NotfoundInterceptor} from './shared/interceptors/notfound.interceptor';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {OAuthService, provideOAuthClient} from "angular-oauth2-oidc";
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ISystemInfo, OAuthProvider} from './models/oauth';
import {AuthTokenService} from './services/auth-token.service';
import {SystemInfoInterceptor} from './shared/interceptors/system-info.interceptor';

declare const require; // Use the require method provided by webpack
export let appInjector: Injector;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PasswordResetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    CustomAuthModule.forRoot({
      config: {
        apiPrefix: environment.apiPrefix,
        userModel: CompanyUser,
        whitelistedApiCalls: ['authentication', 'users/reset', 'oauth2/login', 'system/info'],
        tokenRemover: () => appInjector.get(AuthTokenService).removeToken()
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SystemInfoInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: downloadSystemInfo,
      deps: [HttpClient, SharedVariablesService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CompaniesInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotfoundInterceptor,
      multi: true
    }, {
      provide: TableWebSocketAdapter,
      useClass: TableSocketAdapterService
    },
    SharedVariablesService,
    provideOAuthClient({
      resourceServer: {
        sendAccessToken: true,
        customUrlValidation: (url: string) => url.includes('ws/oauth2/login')
      },
    }),
    AuthTokenService,
    OAuthService
  ]
})
export class AppModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }
}

export function downloadSystemInfo(http: HttpClient, sharedVars: SharedVariablesService) {
  return (): Observable<ISystemInfo> => {
      return http.get<ISystemInfo>('system/info').pipe(tap(res => {
        if (res.oauthInfo.enabled) {
          sharedVars.setVar('oauthProvider', new OAuthProvider(res));
        }
        sharedVars.setVar('weightUnit', res.weightUnit);
        sharedVars.setVar('wsVersion', res.version);
      }));
  }
}

